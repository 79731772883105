@media print {
  body * {
    visibility: hidden;
  }
  .no-print{
    visibility: hidden;
  }

  #section-to-print, #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }

  #section-to-print .no-print{
    visibility: hidden;
  }
}