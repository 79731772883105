@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  font-family: 'Inter', sans-serif;
}

.font-dancing{
  font-family: 'Dancing Script', cursive;
}